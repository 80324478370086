import React from 'react';
import Features from '../components/Features';
import Hero from '../components/Hero';
import { SliderData } from '../data/SliderData';
import InfoSection1 from '../components/InfoSection1';
import InfoSection2 from '../components/InfoSection2';

const Home = () => {
  return (
    <>
      <Hero slides={SliderData} />
      <InfoSection1/>
      <Features />
      <InfoSection2/>
    </>
  );
};

export default Home;
