import React from 'react';
import styled from 'styled-components';
import { Button } from './Button';
import map from '../images/map.svg';


const Section = styled.section`
  width: 100%;
  height: 100%;
  padding: 4rem 0rem;
`;

const Container = styled.div`
  padding: 3rem calc((100vw - 1300px) / 2);
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 800px;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const ColumnLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  line-height: 1.4;
  padding: 1rem 2rem;
  order: ${({ reverse }) => (reverse ? '2' : '1')};

  h1 {
    margin-bottom: 1rem;
    font-size: clamp(1.5rem, 6vw, 2rem);
  }

  p {
    margin-bottom: 2rem;
  }
`;

const ColumnRight = styled.div`
  padding: 1rem 2rem;
  order: ${({ reverse }) => (reverse ? '1' : '2')};
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 768px) {
    order: ${({ reverse }) => (reverse ? '2' : '1')};
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;

    @media screen and (max-width: 768px) {
      width: 90%;
      height: 90%;
    }
  }
`;

const InfoClick = (ButtonLink) => {
  window.open(ButtonLink);
};

const InfoSection2 = () => {
  
  return (
    <Section>
      <Container>
        <ColumnLeft
          reverse='true'
          data-aos='fade-up'
          data-aos-duration='1000'
          data-aos-once='true'
          data-aos-delay='300'
          data-aos-anchor-placement='center bottom'
        >
          <h1>Place to be</h1>
          <p>Kevin`s Penthouse is located in El Médano, South Tenerife, only a few steps away from Cabezo Beach. A short walk takes you to El Médano`s lovely city center or to one of the largest natural beach in Tenerife!
          </p>
          <p>Find us on google maps!</p>
          <Button to= {{ pathname: "https://goo.gl/maps/hyBGM6UNDSrvZ9E38" }} target="_blank" primary='true'>
          Show Location
          </Button>
        </ColumnLeft>
        <ColumnRight reverse='true'>
          <img
            src={map}
            alt='googlemaps'
            data-aos='zoom-out'
            data-aos-duration='1000'
            data-aos-once='true'
            data-aos-delay='300'
            data-aos-anchor-placement='center bottom' 
          />
        </ColumnRight>
      </Container>
    </Section>
  );
};

export default InfoSection2;
