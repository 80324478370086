import React from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { IoMdArrowRoundForward } from 'react-icons/io';
import bathroom1 from '../images/bathroom-1.JPG';
import bathroom2 from '../images/bathroom-2.JPG';
import singlebedroom from '../images/singlebedroom.JPG';
import singlebedroom2 from '../images/singlebedroom2.JPG';
import doublebedroom from '../images/doublebedroom.JPG';
import doublebedroom2 from '../images/doublebedroom2.JPG';
import roofterrace5 from '../images/roofterrace-5.JPG';
import roofterrace2 from '../images/roofterrace-2.JPG';
import roofterrace3 from '../images/roofterrace-3.JPG';
import roofterrace4 from '../images/roofterrace-4.JPG';
import roofterrace6 from '../images/roofterrace-6.JPG';
import living2 from '../images/living-2.JPG';
import living3 from '../images/living-3.JPG';
import workdesk from '../images/workdesk.JPG';
import kitchen from '../images/kitchen.JPG';
import pool from '../images/pool.JPG';



const Section = styled.section`
  width: 100%;
  height: 100%;
  padding: 10rem calc((100vw - 1300px) / 2);
`;

const Container = styled.div`
  height: 100%;
  width: 100%;
  padding: 2rem 1rem;
`;

const Heading = styled.div`
  font-size: 1.5rem;
  padding: 2rem 1rem;
  margin-bottom: 40px;

  @media screen and (max-width: 768px) {
    text-align: start;
  }
`;

const InfoRow = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1rem 0rem;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const InfoWrap = styled.div`
  padding: 0rem 1rem;
  min-height: 550px;
  height: 100%;

  p {
    margin-bottom: 1rem;
    font-weight: 400;
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 1rem;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  max-width: 600px;
  max-height: 400px;
  object-fit: cover;
  margin-bottom: 1rem;
`;

const InfoLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #000d1a;
  width: 140px;
  transition: 0.3s;

  &:hover {
    transform: translateY(-2px);
  }
`;

const Arrow = styled(IoMdArrowRoundForward)`
  margin-left: 10px;
`;

const Listings = () => {
  return (
    <Section>
      <Container>
        <Heading>
          <h1
            data-aos='fade-right'
            data-aos-duration='1000'
            data-aos-once='true'
            data-aos-anchor-placement='center bottom'
          >
            Discover your holiday home!
          </h1>
        </Heading>

        <InfoRow>
          <InfoWrap
            data-aos='zoom-out-up'
            data-aos-duration='1200'
            data-aos-once='true'
            data-aos-anchor-placement='center bottom'
          >
            <Image src={pool} alt='pool' />
            <p>Communal Pool for adults and children</p>
          </InfoWrap>

          <InfoWrap
            data-aos='zoom-out-down'
            data-aos-duration='1200'
            data-aos-once='true'
            data-aos-anchor-placement='center bottom'
          >
            <Image
              src={roofterrace2}
              alt='teide view'
              css={`
                margin-top: 120px;
                @media screen and (max-width: 768px) {
                  margin-top: 0px;
                }
              `}
            />
            <p>View to El Teide from your private rooftop terrace</p>
          </InfoWrap> 
        </InfoRow>
        <InfoRow>
        <InfoWrap
            data-aos='zoom-out-up'
            data-aos-duration='1200'
            data-aos-once='true'
            data-aos-anchor-placement='center bottom'
          >
            <Image src={roofterrace4} alt='rooftop terrace' />
            <p>Rooftop Terrace</p>
          </InfoWrap>

          <InfoWrap
            data-aos='zoom-out-down'
            data-aos-duration='1200'
            data-aos-once='true'
            data-aos-anchor-placement='center bottom'
          >
            <Image
              src={roofterrace3}
              alt='home'
              css={`
                margin-top: 120px;
                @media screen and (max-width: 768px) {
                  margin-top: 0px;
                }
              `}
            />
            <p>Rooftop Terrace</p>
          </InfoWrap> 
        </InfoRow>

        <InfoRow>
        <InfoWrap
            data-aos='zoom-out-up'
            data-aos-duration='1200'
            data-aos-once='true'
            data-aos-anchor-placement='center bottom'
          >
            <Image src={roofterrace6} alt='rooftop terrace' />
            <p>Rooftop Terrace</p>
          </InfoWrap>

          <InfoWrap
            data-aos='zoom-out-down'
            data-aos-duration='1200'
            data-aos-once='true'
            data-aos-anchor-placement='center bottom'
          >
            <Image
              src={roofterrace5}
              alt='home'
              css={`
                margin-top: 120px;
                @media screen and (max-width: 768px) {
                  margin-top: 0px;
                }
              `}
            />
            <p>Rooftop Terrace</p>
          </InfoWrap> 
        </InfoRow>

        <InfoRow>
          <InfoWrap
            data-aos='zoom-out-up'
            data-aos-duration='1200'
            data-aos-once='true'
            data-aos-anchor-placement='center bottom'
          >
            <Image src={singlebedroom} alt='single bedroom' />
            <p>Single Bedroom with two 90x200 beds</p>
          </InfoWrap>

          <InfoWrap
            data-aos='zoom-out-down'
            data-aos-duration='1200'
            data-aos-once='true'
            data-aos-anchor-placement='center bottom'
          >
            <Image
              src={singlebedroom2}
              alt='single bedroom closet'
              css={`
                margin-top: 120px;
                @media screen and (max-width: 768px) {
                  margin-top: 0px;
                }
              `}
            />
            <p>single bedroom closet with plenty of space</p>
          </InfoWrap> 
        </InfoRow>

        <InfoRow>
          <InfoWrap
            data-aos='zoom-out-up'
            data-aos-duration='1200'
            data-aos-once='true'
            data-aos-anchor-placement='center bottom'
          >
            <Image src={doublebedroom} alt='double bedroom' />
            <p>Double bedroom with 180x200cm matrace</p>
          </InfoWrap>

          <InfoWrap
            data-aos='zoom-out-down'
            data-aos-duration='1200'
            data-aos-once='true'
            data-aos-anchor-placement='center bottom'
          >
            <Image
              src={doublebedroom2}
              alt='single bedroom closet'
              css={`
                margin-top: 120px;
                @media screen and (max-width: 768px) {
                  margin-top: 0px;
                }
              `}
            />
            <p>double bedroom closet with plenty of space</p>
          </InfoWrap> 
        </InfoRow>
        
        <InfoRow>
        <InfoWrap
            data-aos='zoom-out-up'
            data-aos-duration='1200'
            data-aos-once='true'
            data-aos-anchor-placement='center bottom'
          >
            <Image src={living2} alt='living room' />
            <p>Living room with smart TV</p>
          </InfoWrap>

          <InfoWrap
            data-aos='zoom-out-down'
            data-aos-duration='1200'
            data-aos-once='true'
            data-aos-anchor-placement='center bottom'
          >
            <Image
              src={living3}
              alt='dining area'
              css={`
                margin-top: 120px;
                @media screen and (max-width: 768px) {
                  margin-top: 0px;
                }
              `}
            />
            <p>and dining area with pool view</p>
          </InfoWrap> 
        </InfoRow>


        <InfoRow>
        <InfoWrap
            data-aos='zoom-out-up'
            data-aos-duration='1200'
            data-aos-once='true'
            data-aos-anchor-placement='center bottom'
          >
            <Image src={workdesk} alt='remote workdesk' />
            <p>remote work desk with highspeed internet &#40;300mb&#41;</p>
          </InfoWrap>

          <InfoWrap
            data-aos='zoom-out-down'
            data-aos-duration='1200'
            data-aos-once='true'
            data-aos-anchor-placement='center bottom'
          >
            <Image
              src={kitchen}
              alt='kitchen-cooking'
              css={`
                margin-top: 120px;
                @media screen and (max-width: 768px) {
                  margin-top: 0px;
                }
              `}
            />
            <p>well equipped kitchen</p>
          </InfoWrap> 
        </InfoRow>
      </Container>
    </Section>
  );
};

export default Listings;
