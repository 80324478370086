import React from 'react';
import styled from 'styled-components';
import { Button } from './Button';
import Medano from '../images/medano.JPG';


const Section = styled.section`
  width: 100%;
  height: 100%;
  padding: 4rem 0rem;
`;

const Container = styled.div`
  padding: 3rem calc((100vw - 1300px) / 2);
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 800px;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const ColumnLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  line-height: 1.4;
  padding: 1rem 2rem;
  order: ${({ reverse }) => (reverse ? '2' : '1')};

  h1 {
    margin-bottom: 1rem;
    font-size: clamp(1.5rem, 6vw, 2rem);
  }

  p {
    margin-bottom: 2rem;
  }
`;

const ColumnRight = styled.div`
  padding: 1rem 2rem;
  order: ${({ reverse }) => (reverse ? '1' : '2')};
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 768px) {
    order: ${({ reverse }) => (reverse ? '2' : '1')};
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;

    @media screen and (max-width: 768px) {
      width: 90%;
      height: 90%;
    }
  }
`;


const InfoClick = (ButtonLink) => {
  window.open(ButtonLink);
};

const InfoSection1 = () => {
  return (
    
    <Section>
      <Container>
        <ColumnLeft
          reverse='true'
          data-aos='fade-up'
          data-aos-duration='1000'
          data-aos-once='true'
          data-aos-delay='100'
          data-aos-anchor-placement='center bottom'
        >
          <h1>El Médano</h1>
          <p>El Médano, a lively village perfect for everyone who loves an easy lifestyle, water sports like windsurf, surf and kite surf or just catch the sun on its lovely beaches, long walks on the beachfront, have drinks with friends or enjoy nice dinners in one of the local or international restaurants nearby.</p>
          <p>Visit El Medano - One of Tenerife`s most chilled and laid back resorts!</p>
          <Button to= {{ pathname: "https://www.tripadvisor.com/Tourism-g1190083-El_Medano_Tenerife_Canary_Islands-Vacations.html" }} target="_blank" primary='true'>
            Discover
            
          </Button>
        </ColumnLeft>
        <ColumnRight reverse='true'>
          <img
            src={Medano}
            alt='El Medano'
            data-aos='zoom-out'
            data-aos-duration='1000'
            data-aos-once='true'
            data-aos-delay='100'
            data-aos-anchor-placement='center bottom'
            
          />
        </ColumnRight>
      </Container>
    </Section>
  );
};

export default InfoSection1;
