import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import CookieConsent from "react-cookie-consent";

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App />
      <CookieConsent
      location="bottom"
      expires={365}>This website uses cookies to enhance the user experience.</CookieConsent>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
