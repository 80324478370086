import React from 'react';
import styled from 'styled-components';

const Section = styled.section`
  background: #000d1a;
  padding: 12rem 0rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Container = styled.div`
  background: #fff;
  padding: 3rem 2rem;
  position: relative;
`;

const Wrap = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const ColumnLeft = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0px -15px;
  justify-content: flex-start;
  padding: 1rem;
`;

const Content = styled.div`
  flex: 0 0 50%;

  @media screen and (max-width: 768px) {
    flex: 0 0 100%;
    max-width: 100%;
    margin-top: 250px;
  }

  h1 {
    margin-bottom: 2rem;
    font-size: 2rem;
  }

  p {
    margin-bottom: 1rem;
    line-height: 1.5;
  }
`;

const ColumnRight = styled.div`
  position: absolute;
  top: -80px;
  right: 0;
  max-width: 850px;
  height: 140%;
  width: 45%;
  padding-left: 1rem;

  @media screen and (max-width: 768px) {
    height: 320px;
    top: -65px;
    width: 80%;
    margin: 0 auto;
    left: 0;
  }
`;



const Features = () => {
    return (
        <Section>
            <Container>
                <Wrap>
                    <ColumnLeft>
                        <Content
                            data-aos='fade-right'
                            data-aos-duration='1200'
                            data-aos-delay='300'
                            data-aos-once='true'
                            data-aos-anchor-placement='center bottom'
                        >
                            <h1>Impressum - Legal Disclosure</h1>
                            <p>
                            The following information (Impressum) is required under German law.
                            </p>
                            <p><b>Holiday Apartment Rental - Kevin`s Penthouse</b></p>
                            <p>Kevin Bebensee</p>
                            <p>Ehrenbergstraße 64</p>
                            <p>22767 Hamburg - Germany</p>
                            <p>Email: kevinbebensee@hotmail.de</p>
                            <h3>Online Dispute Resolution website of the EU Commission</h3>
                            <p>In order for consumers and traders to resolve a dispute out-of-court, the European Commission developed the Online Dispute Resolution Website: <a href="http://www.ec.europa.eu/consumers/odr" target="_blank" rel="noopener">www.ec.europa.eu/consumers/odr</a></p>
                            <h3>Legal disclaimer</h3>
                            <p>The contents of these pages were prepared with utmost care. Nonetheless, we cannot assume liability for the timeless accuracy and completeness of the information.</p>
                            <p>Our website contains links to external websites. As the contents of these third-party websites are beyond our control, we cannot accept liability for them. Responsibility for the contents of the linked pages is always held by the provider or operator of the pages.</p>
                            <h3>Data protection</h3>
                            <p>In general, when visiting the website of <em>Kevin`s Penthouse</em>, no personal data are saved. However, these data can be given on a voluntary basis. No data will be passed on to third parties without your consent. We point out that in regard to unsecured data transmission in the internet (e.g. via email), security cannot be guaranteed. Such data could possibIy be accessed by third parties.</p>
                            <h3>Google Analytics </h3>
                            <p>This website uses Google Analytics, a web analytics service provided by Google, Inc. (»Google«). Google Analytics uses cookies, which are text files placed on your computer, to help the website analyze how users use the site. The information generated by the cookie about your use of the website (including your IP address) will be transmitted to and stored by Google on servers in the United States. In case of activation of the IP anonymization, Google will truncate/anonymize the last octet of the IP address for Member States of the European Union as well as for other parties to the Agreement on the European Economic Area. Only in exceptional cases, the full IP address is sent to and shortened by Google servers in the USA. On behalf of the website provider Google will use this information for the purpose of evaluating your use of the website, compiling reports on website activity for website operators and providing other services relating to website activity and internet usage to the website provider. Google will not associate your IP address with any other data held by Google. By using this website, you consent to the processing of data about you by Google in the manner and for the purposes set out above.</p>


                            <h4>How to prevent being tracked by Google Analytics</h4>
                            <ul>
                            <li>You may refuse the use of cookies by selecting the appropriate settings on your browser. However, please note that if you do this, you may not be able to use the full functionality of this website.</li>
                            <li>Furthermore you can prevent Google's collection and use of data (cookies and IP address) by downloading and installing the browser plug-in available under <a title="Opt out of Google Analytics on lb website" href="https://tools.google.com/dlpage/gaoptout?hl=en" target="_blank" rel="noopener">https://tools.google.com/dlpage/gaoptout?hl=en</a>.</li>
                            </ul>                        
                        </Content>
                    </ColumnLeft>

                </Wrap>
            </Container>
        </Section>
    );
};

export default Features;

