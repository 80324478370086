import ImageOne from '../images/house-1.JPG';
import ImageTwo from '../images/house-2.JPG';
import ImageThree from '../images/house-3.JPG';
import ImageFour from '../images/house-4.JPG';
import ImageFive from '../images/roofterrace-7.JPG';
import ImageSix from '../images/kitchen.JPG';
import ImageSeven from '../images/roofterrace-4.JPG';

export const SliderData = [
  {
    title: 'Private Rooftop Terrace',
    desc: 'Kevin`s Penthouse is a very stylish top floor holiday home next to El Cabezo beach with a massive private, wind protected rooftop terrace!',
    path: '/Pictures',
    label: 'View more Pictures',
    image: ImageOne,
    alt: 'House'
  },
  {
    title: 'Enjoy the sun',
    desc: 'with sunloungers, outdoor shower, dining area and an amazing view on the Teide!',
    path: '/Pictures',
    label: 'View more Pictures',
    image: ImageTwo,
    alt: 'House'
  },
  {
    title: 'Close to the beach',
    desc: 'wake up just a few steps from the sea',
    path: '/Pictures',
    label: 'View more Pictures',
    image: ImageFive,
    alt: 'House'
  },
  {
    title: 'Fully equipped 2-Bedroom Apartment',
    desc: 'provides a lightflooded living room with an open kitchen, remote workdesk and two modern Bathrooms',
    path: '/Pictures',
    label: 'View more Pictures',
    image: ImageThree,
    alt: 'House'
  },
  {
    title: 'High quality holiday home',
    desc: 'offers you a 180cm double bed and two single beds in a young design.',
    path: '/Pictures',
    label: 'View more Pictures',
    image: ImageFour,
    alt: 'House'
  }
];

export const SliderDataTwo = [
  {
    title: 'High quality holiday home',
    desc: 'offers you a 180cm double bed and two single beds in a young design.',
    path: '/Pictures',
    label: 'View more Pictures',
    image: ImageSix,
    alt: 'House'
  }
];
